import { createModal } from "@/js/components/modal";
import "./styles.scss";

const getContent = (dzenLink = "") => `
  <div class="flex items-center justify-center w-screen h-screen max-w-full" data-modal-close="video-modal">
    <div class="iframe-wrapper rounded-4xl overflow-hidden">
      <iframe
        class="w-full h-full object-contain"
        src="${dzenLink}"
        frameborder="0"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen=""
        load="lazy">
      </iframe>
    </div>
  </div>
`;

export const createVideoModal = (dzenLink = "") => {
  if (!dzenLink) return {};

  const modal = createModal({
    selector: `video-modal`,
  });
  
  modal.on("close", () => {
    modal.target.addEventListener("transitionend", resetModalContent);
  });
  
  modal.on("beforeOpen", () => {
    modal.setContent(getContent(dzenLink));
  });

  const resetModalContent = () => {
    modal.setContent("");
    modal.target.removeEventListener("transitionend", resetModalContent);
  };

  return modal;
}