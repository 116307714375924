import "@js/app";
import "@css-pages/homepage";
import Slider from "@libs/slider/src/js/ClassSlider";
import { createVideoModal } from "@/js/components/video-modal/video-modal";

createVideoModal("https://dzen.ru/embed/vD3edktINd1c?from_block=partner&from=zen&mute=0&autoplay=0&tv=0");

document.addEventListener("DOMContentLoaded", () => {
  const partnersSlider = new Slider({
    root: document.querySelector(".home__dillers-slider"),
    type: "gallery",
    autoplay: false,
    loop: false,
    lazy: false,
    delay: 3500,
    speed: 1000,
  });

  const mainSlider = new Slider({
    root: document.querySelector("#banner"),
    autoplay: true,
    loop: true,
    delay: 3500,
    speed: 200,
  });
});