import "../styles/styles.scss";
import { debounce } from "./utils.js";

export default class Slider {
  constructor(options) {
    if (!options?.root) {
      throw new Error("Root element is required");
    }

    // Параметры слайдера
    this.options = options || {};
    this.root = options.root;
    this.type = options.type || "fade";
    this.isLazy = options.lazy ?? true;
    this.activeSlideIndex = options.startSlide || 0;
    this.controlsEnabled = options.controls ?? true;
    this.thumbnailsEnabled = options.thumbnails || false;
    this.thumbLink = options.thumbLink || false;
    this.paginationEnabled = options.pagination ?? true;
    this.loop = options.loop;
    this.autoplay = options.autoplay;
    this.speed = options.speed ?? 200;
    this.delay = options.delay ?? 1000;
    this.lockSlider = true;
    this.carousel = [];
    this.loopInterval = null;

    if (this.thumbnailsEnabled) {
      this.paginationEnabled = false;
    }

    this.initElements();
    
    if (!(this.slides.length && this.wrapSlides)) {
      throw new Error("Required slider elements not found");
    }

    this.gotoDebounced = debounce(this.goto.bind(this), this.speed);
    this.handleResizeDebounced = debounce(this.handleResize.bind(this), 100);

    this.root.setAttribute("data-slider-type", this.type);
    this.setup();
  }

  initElements() {
    this.slides = this.root.querySelectorAll(".slider__slide");
    this.wrapSlides = this.root.querySelector(".slider__slides");
    
    this.buttons = {
      root: this.root.querySelector(".slider__buttons"),
      prev: this.root.querySelector(".slider__button--prev"),
      next: this.root.querySelector(".slider__button--next"),
    };

    this.thumbnails = {
      root: this.root.querySelector(".slider__thumbnails"),
      inner: this.root.querySelector(".slider__thumbnails-inner"),
      items: [],
    };

    this.pagination = {
      root: this.root.querySelector(".slider__pagination"),
      items: [],
    };
  }
  setup() {
    if (!this.controlsEnabled || this.slides.length < 2) {
      this.hide(this.buttons.root);
    } else {
      this.show(this.buttons.root);
    }

    if (!this.paginationEnabled || this.slides.length < 2) {
      this.hide(this.pagination.root);
    } else {
      this.show(this.pagination.root);
      this.createPaginationItems();
    }

    if (this.thumbnailsEnabled && this.slides.length > 1) {
      this.createThumbnails();
    }

    this.gotoDebounced(this.activeSlideIndex);
    
    setTimeout(() => {
      document.body.style.setProperty(
        "--slider-transition-duration",
        `${this.speed}ms`
      );
      this.startLoop();
    }, 0);

    this.bind();
  }
  bind() {
    this.root.addEventListener("click", this.handleClicks.bind(this));
    this.root.addEventListener("transitionend", this.handleTransitionEnd.bind(this));
    document.addEventListener("keydown", this.handleKeydown.bind(this));
    window.addEventListener("resize", this.handleResizeDebounced);
    
    if (this.thumbLink) {
      this.thumbnails.root?.addEventListener("click", this.handleStopThumbLink.bind(this));
    }
    
    document.addEventListener("pointerdown", this.handleSwipeSlide.bind(this));
  }
  unbind() {
    this.root.removeAttribute("data-slider-type");
    this.root.removeEventListener("click", this.handleClicks);
    this.root.removeEventListener("transitionend", this.handleTransitionEnd);
    document.removeEventListener("keydown", this.handleKeydown);
    window.removeEventListener("resize", this.handleResizeDebounced);
    
    if (this.thumbLink) {
      this.thumbnails.root?.removeEventListener("click", this.handleStopThumbLink);
    }
    
    document.removeEventListener("pointerdown", this.handleSwipeSlide);
  }
  translate(el, dist) {
    const style = el?.style;
    if (!style) return;

    style.webkitTransform = `translate(${dist}px,0) translateZ(0)`;
    style.msTransform = 
    style.MozTransform = 
    style.OTransform = `translateX(${dist}px)`;
  }
  hide(el) {
    el?.classList.add("hidden");
  }
  show(el) {
    el?.classList.remove("hidden");
  }
  createPaginationItems() {
    const fragment = document.createDocumentFragment();
    let paginationAmount = this.slides.length;
    
    if (this.type === "gallery") {
      const slideWidth = this.slides[0].offsetWidth;
      const rootWidth = this.root.offsetWidth;
      paginationAmount = Math.ceil((slideWidth * this.slides.length) / rootWidth);
    }
    
    for (let i = 0; i < paginationAmount; i++) {
      const bullet = document.createElement("div");
      bullet.innerHTML = `
        <li class="slider__pagination-item">
          <span class="slider__pagination-bullet" aria-label="Go to slide ${i + 1}">
            Dot slide
          </span>
        </li>
      `;
      fragment.append(bullet.children[0]);
    }
    
    if (paginationAmount > 1) {
      this.pagination.root.append(...fragment.children);
      this.pagination.items.push(...this.pagination.root.children);
    }
  }
  createThumbnails() {
    const fragment = document.createDocumentFragment();
    
    const getThumb = (link, isVideo) => {
      const videoClass = isVideo ? " video-item" : "";
      if (this.thumbLink) {
        return `
          <a href="${link}" class="slider__thumbnails-item${videoClass}">
            <img width="47" height="32" src="${link}" alt />
          </a>`;
      }
      return `
        <div class="slider__thumbnails-item${videoClass}">
          <img width="47" height="32" src="${link}" alt />
        </div>`;
    };
    
    for (let i = 0; i < this.slides.length; i++) {
      const slideIframe = this.slides[i].querySelector("iframe");
      const isVideo = !!slideIframe;
      const slideImg = this.slides[i].querySelector("[data-thumb]");
      const thumbnailLink = slideImg.dataset.thumb;
      
      const bullet = document.createElement("div");
      bullet.innerHTML = getThumb(thumbnailLink, isVideo);
      
      this.carousel.push({
        isVideo,
        src: slideImg.dataset.src,
        thumb: thumbnailLink,
      });
      
      fragment.append(bullet.children[0]);
    }
    
    this.thumbnails.inner?.append(...fragment.children);
    this.thumbnails.items.push(...(this.thumbnails.inner?.children || []));
  }
  handlePaginationItems() {
    if (!this.paginationEnabled || !this.lockSlider) return;
      const step = this.pagination.root.offsetWidth / 5;
      const offset = step * 2 - step * this.activeSlideIndex;
      this.pagination.items.forEach((bullet) => {
        bullet.removeAttribute("data-offset");
      });
      for (let i = this.activeSlideIndex; i <= this.activeSlideIndex + 4; i++) {
        let n = this.handleIndex(i - 2);
        this.pagination.items[n]?.setAttribute(
          "data-offset",
          n - this.activeSlideIndex + 2
        );
      }
      this.pagination.items.forEach((bullet) => {
        this.translate(bullet, offset, this.speed);
      });
  }
  handleIndex(slideIndex) {
    if (this.loop) {
      return (this.slides.length + (slideIndex % this.slides.length)) % this.slides.length;
    } else {
      let paginationAmount = this.slides.length;
      
      if (this.type === "gallery") {
        const slideWidth = this.slides[0].offsetWidth;
        const rootWidth = this.root.offsetWidth;
        paginationAmount = Math.ceil((slideWidth * this.slides.length) / rootWidth);
      }
      
      return Math.min(paginationAmount - 1, Math.max(slideIndex, 0));
    }
  }
  next() {
    this.gotoDebounced(this.activeSlideIndex + 1);
  }
  prev() {
    this.gotoDebounced(this.activeSlideIndex - 1);
  }
  goto(slideIndex) {
    if (!this.lockSlider) return;
    
    this.activeSlideIndex = this.handleIndex(slideIndex);
    this.handlePaginationItems();
    this.handleButtons();
    this.handleSliderItems();
    
    if (this.isLazy) {
      this.handleLazyLoading();
    }
    
    this.handleThumbnailsItems();
    this.stopLoop();
  }
  startLoop() {
    if (!this.autoplay || this.delay <= 0) return;
    
    this.stopLoop();
    this.loopInterval = setInterval(() => this.next(), this.delay);
  }
  stopLoop() {
    clearInterval(this.loopInterval);
  }
  handleClicks(event) {
    if (event.target.closest(".slider__buttons")) {
      this.handleControlButtonClick(event);
    } else if (event.target.closest(".slider__pagination")) {
      this.handlePaginationBulletClick(event);
    } else if (event.target.closest(".slider__thumbnails")) {
      this.handleThumbnailsClick(event);
    }
  }
  handleSliderItems() {
    if (!this.wrapSlides) return;
    const width = this.wrapSlides.offsetWidth;
    if (!this.lockSlider) return
    if (this.type === "fade")
        this.slides.forEach((slide, index) => {
        slide.classList.remove("slider__slide--active");
        index === this.activeSlideIndex && slide.classList.add("slider__slide--active"), this.translate(slide, -width * index);
      });
    if (this.type === "gallery") {
      const slideWidth = this.root.querySelector(".slider__slide").offsetWidth;

      const slidesAmount = Math.round(width / slideWidth);
      const slidesAmountNext = [...this.slides].slice(this.activeSlideIndex * slidesAmount, (this.activeSlideIndex + 1) * slidesAmount).length;
      const translateX = width * (this.activeSlideIndex - 1) + slidesAmountNext * slideWidth;

      this.translate(this.wrapSlides, -translateX);
    }
  }
  handleButtons() {
    if (this.loop) {
      return;
    }

    this.activeSlideIndex === 0 ? this.hide(this.buttons.prev) : this.show(this.buttons.prev);
    this.activeSlideIndex === this.slides.length - 1
      ? this.hide(this.buttons.next)
      : this.show(this.buttons.next);
  }
  handleControlButtonClick({ target }) {
    const nextBtn = target.closest(".slider__button--next"),
          prevBtn = target.closest(".slider__button--prev");
    
    if (target.classList.contains("slider__button--next") || nextBtn) {
      this.next();
    } else if (target.classList.contains("slider__button--prev") || prevBtn) {
      this.prev();
    }
  }
  handlePaginationBulletClick({ target }) {
    const item = target.closest(".slider__pagination-item") || target;
    const index = this.pagination.items.indexOf(item);
    
    if (index >= 0) {
      this.goto(index);
    }
  }
  handleThumbnailsItems() {
    if (!this.thumbnailsEnabled || this.slides.length === 1) {
      return;
    }
    const widthOuter = this.thumbnails.root?.offsetWidth || 0;
    const widthInner = this.thumbnails.inner?.offsetWidth || 0;
    if (widthInner > widthOuter) {
      const step = (widthInner - widthOuter) / (this.thumbnails.items.length - 1);
      let offset = -step * this.activeSlideIndex;
      this.thumbnails.items.forEach((thumb) => {
        translate(thumb, offset, this.speed);
      });
    } else {
      let offset = (widthOuter - widthInner) / 2;
      this.thumbnails.items.forEach((thumb) => {
        this.translate(thumb, offset, speed);
      });
    }

    this.thumbnails.items.forEach((thumb) => {
      thumb.classList.remove("js-active");
    });

    this.thumbnails.items[this.activeSlideIndex]?.classList.add("js-active");
  }
  handleThumbnailsClick(event) {
    const item = event.target.closest(".slider__thumbnails-item") || event.target;
    const index = this.thumbnails.items?.indexOf(item);
    
    if (index >= 0) {
      this.goto(index);
    }
  }
  handleKeydown(event) {
    if (event.key === "ArrowRight") {
      this.next();
    } else if (event.key === "ArrowLeft") {
      this.prev();
    }
  }
  handleStopThumbLink(e) {
    e.preventDefault();
  }
  handleResize() {
    this.handleSliderItems();
    this.handlePaginationItems();
    this.handleThumbnailsItems();
  }
  handleLazyLoading() {
    const slide = this.slides[this.activeSlideIndex];
    const img = slide?.querySelector("img");
    
    if (img) {
      img.src = img.dataset.src;
    }
  }
  handleTransitionEnd(event) {
    const check = event.target.classList.contains("slider__pagination-item");
    
    if (check || (!check && this.autoplay)) {
      this.startLoop();
    }
  }
  handleSwipeSlide = (event) => {
    
    const slide = event.target.closest('.slider__slide');
    
    if (!slide || !this.lockSlider) {
      document.onmousemove = (evt) => {
        if (slide) evt.preventDefault();
      };
      document.onclick = (evt) => {
        if (slide) evt.preventDefault();
      };
      return;
    }
  
    document.onclick = null;
    document.onmousemove = null;
    document.ontouchmove = null;
    document.onpointerup = null;
    document.ontouchend = null;
  
    const slideWidth = slide.offsetWidth;
    const slidesTranslate = +this.wrapSlides.style.transform?.split('translate(')[1]?.split(',')[0].replace('px', '') || 0;
    
    const startPosition = {
      x: event.screenX ?? event.changedTouches[0].screenX,
      y: event.screenY ?? event.changedTouches[0].screenY
    };
  
    const elTransition = this.type === 'gallery' ? this.wrapSlides : slide;
    let nextSlide = this.slides[0];
    
    document.body.onscroll = (evt) => evt.preventDefault();
    
    const startDragSlide = (evt) => {
      if (evt.type === 'mousemove') evt.preventDefault();
      
      const screenX = evt.screenX ?? evt.changedTouches[0].screenX;
      const distance = startPosition.x - screenX;
      
      elTransition.style.transition = '0s';
      
      if (this.type === 'gallery') {
        if (this.pagination.items.length) {
          this.translate(this.wrapSlides, slidesTranslate + distance * -1);
        }
      } else {
        this.setNextSlide(false);
        nextSlide = this.slides[this.handleIndex(distance > 0 ? 
          this.activeSlideIndex + 1 : 
          this.activeSlideIndex - 1)];
        
        const opacity = Math.abs(1 - Math.abs(distance) / slideWidth);
        this.setNextSlide(true, opacity);
      }
    };
  
    const endDragSlide = (evt) => {
      document.onmousemove = null;
      document.ontouchmove = null;
      document.onpointerup = null;
      document.ontouchend = null;
      document.body.onscroll = null;
      
      if (evt.type === 'pointerup') evt.preventDefault();
      this.lockSlider = true;
      
      const screenX = evt.screenX ?? evt.changedTouches[0].screenX;
      const screenY = evt.screenY ?? evt.changedTouches[0].screenY;
      
      const distance = {
        x: startPosition.x - screenX,
        y: startPosition.y - screenY
      };
      
      // Если движение было слишком маленьким - игнорируем
      const isSetStopLink = Math.abs(distance.x) < 2 && Math.abs(distance.y) < 2;
      if (isSetStopLink) return;
      
      // Восстанавливаем анимацию
      elTransition.style.transition = null;
      
      if (this.type === 'fade') {
        this.setNextSlide();
        setTimeout(() => {
          this.lockSlider = true;
          slide.style.opacity = null;
          this.setNextSlide(false, null);
        }, this.speed);
      }
      
      // Определяем направление свайпа
      if (distance.x > 0) {
        this.goto(this.activeSlideIndex + 1);
      } else {
        this.goto(this.activeSlideIndex - 1);
      }
      
      if (this.type === 'fade') this.lockSlider = false;
    };
  
    // Устанавливаем обработчики
    this.root.onmousemove = startDragSlide;
    this.root.ontouchmove = startDragSlide;
    this.root.onpointerup = endDragSlide;
    this.root.ontouchend = endDragSlide;
    
    // Временно разблокируем слайдер для анимации
    this.lockSlider = false;
  }  
  setNextSlide = (isAdd = true, opacity = 0) => {
    const nextSlide = this.slides[this.handleIndex(
      this.activeSlideIndex + (isAdd ? 1 : -1)
    )];
    
    if (nextSlide) {
      nextSlide.classList[isAdd ? 'add' : 'remove']('slider__slide--next');
      const currentSlide = this.slides[this.activeSlideIndex];
      currentSlide.style.zIndex = isAdd ? '10' : null;
      currentSlide.style.opacity = opacity;
    }
  }
  get activeSlide() {
    return this.activeSlideIndex;
  }
  destroy() {
    this.unbind();
    this.root.remove();
    this.emitter.emit("destroy");
  }
  on(name, fn) {
    this.emitter.on(name, fn);
  }
}